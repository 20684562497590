@font-face {
    font-display: auto;
    font-family: "gordita_regular";
    src: url("https://talrop-learn-static-and-media.s3.ap-south-1.amazonaws.com/static/font/yiaai/GorditaRegular.woff")
        format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: auto;
    font-family: "gordita_medium";
    src: url("https://talrop-learn-static-and-media.s3.ap-south-1.amazonaws.com/static/font/yiaai/GorditaMedium.woff")
        format("woff");
    font-weight: 400;
    font-style: normal;
}

/* @font-face {
    font-family: "gordita_bold";
    src: url("./font/gordita/GorditaBold.woff") format("woff");
    font-weight: 400;
    font-style: normal;
} */

@font-face {
    font-family: "parisienne";
    src: url("https://talrop-learn-static-and-media.s3.ap-south-1.amazonaws.com/static/font/yiaai/Parisienne-Regular.woff.ttf")
        format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Merriweather";
    src: url("https://talrop-learn-static-and-media.s3.ap-south-1.amazonaws.com/static/font/yiaai/Merriweather-Regular.woff.ttf")
        format("woff");
    font-weight: 400;
    font-style: normal;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
